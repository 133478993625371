import React from "react"
import Layout from "../components/layout"

const Pasovi = () => {
  return <Layout>
    	<div className="akc-pasovi">
				<h2>Pasovi</h2>
			</div>
			<div className="akc-pasovi-content">
				<div className="akc-content-inner">
					<a
						target="_blank"
						href="https://drive.google.com/file/d/1M6Td5HzkoxSv9stA511cwIWGPRdtYYcw/view?usp=sharing"
						className="akc-pasovi-item rumen"
					>
						<i className="fas fa-bacon rumen"></i>
						<h2>Rumeni pas</h2>
					</a>
					<a
						target="_blank"
						href="https://drive.google.com/file/d/1I-yuYcQY7oOLhwa2obq0dDe5Yjke8nta/view?usp=sharing"
						className="akc-pasovi-item oranzen"
					>
						<i className="fas fa-bacon oranzen"></i>
						<h2>Oranžen pas</h2>
					</a>
					<a
						target="_blank"
						href="https://drive.google.com/file/d/1cuLPQGsvp0SpteuZHLuwJfAD1nyfIVSI/view?usp=sharing"
						className="akc-pasovi-item zelen"
					>
						<i className="fas fa-bacon zelen"></i>
						<h2>Zelen pas</h2>
					</a>
					<a
						target="_blank"
						href="https://drive.google.com/file/d/10BoO6Tlzznw6d_yJczZAEUthQPpM_rnf/view?usp=sharing"
						className="akc-pasovi-item moder"
					>
						<i className="fas fa-bacon moder"></i>
						<h2>Moder pas</h2>
					</a>
					<a
						target="_blank"
						href="https://drive.google.com/file/d/1OtL9dCdXmtIqEJZ9k8utXv2JlCzdupEx/view?usp=sharing"
						className="akc-pasovi-item rjav"
					>
						<i className="fas fa-bacon rjav"></i>
						<h2>Rjav pas</h2>
					</a>
				</div>
			</div>
    </Layout>
}

export default Pasovi
